import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext"; // Adjust the import path if needed

const Navbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate(); // For programmatic navigation

  const handleLogout = async () => {
    await logout(); // Clear authentication state
    navigate("/");
  };

  return (
    <nav className="flex justify-between items-center bg-[#06413D] p-4 text-white fixed top-0 w-full z-10">
      <div>
        <NavLink to="/">
          <img src="logo-no-background.svg" alt="logo" className="w-20" />
        </NavLink>
      </div>

      <div className="flex items-center space-x-4">
        <NavLink className="text-white hover:bg-gray-400 mx-[1rem]" to="/">
          Home
        </NavLink>
        <NavLink to="/about" className="mx-[1rem] text-white hover:bg-gray-400">
          About
        </NavLink>
        <NavLink
          to="/contact"
          className="mx-[1rem] text-white hover:bg-gray-400"
        >
          Contact
        </NavLink>
      </div>
      <div className="flex items-center space-x-4">
        {isAuthenticated() ? (
          <>
            <NavLink
              to="/dashboard"
              className="text-white hover:bg-gray-400 mx-[1rem]"
            >
              My Recipes
            </NavLink>
            <button
              onClick={handleLogout}
              className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded"
            >
              Logout
            </button>
          </>
        ) : (
          <>
            <NavLink
              to="/login"
              className="text-white hover:bg-gray-400 mx-[1rem]"
            >
              Login
            </NavLink>
            <NavLink
              to="/register"
              className="text-white hover:bg-gray-400 mx-[1rem]"
            >
              Register
            </NavLink>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

const Contact = ({ serverUrl }) => {
  return (
    <div className="w-[80%] mx-auto py-40">
      <br />
      <h1 className="text-center text-xlg font-semibold">Contact Us</h1>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          subject: "",
          message: "",
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(15, "Must be 15 characters or less")
            .required("Required"),
          lastName: Yup.string()
            .max(20, "Must be 20 characters or less")
            .required("Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          subject: Yup.string()
            .max(50, "Must be 50 characters or less")
            .required("Required"),
          message: Yup.string()
            .max(500, "Must be 500 characters or less")
            .required("Required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          // alert(JSON.stringify(values, null, 2));

          // Send form data to jpniyitanga@gmail.com
          await axios
            .post(`${serverUrl}/messages}`, values)
            .then((response) => {
              console.log("Form data sent successfully", response.data);
            })
            .catch((error) => {
              console.log("Failed to send form data.", error.message);
            });
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col">
            <label htmlFor="firstName">First Name</label>
            <Field
              name="firstName"
              type="text"
              className="border rounded-md p-2 mb-2"
            />
            <ErrorMessage name="firstName" />

            <label htmlFor="lastName">Last Name</label>
            <Field
              name="lastName"
              type="text"
              className="border rounded-md p-2 mb-2"
            />
            <ErrorMessage name="lastName" />

            <label htmlFor="email">Email Address</label>
            <Field
              name="email"
              type="email"
              className="border rounded-md p-2 mb-2"
            />
            <ErrorMessage name="email" />

            <label htmlFor="subject">Subject</label>
            <Field
              name="subject"
              type="text"
              className="border rounded-md p-2 mb-2"
            />
            <ErrorMessage name="subject" />

            <label htmlFor="email">Message</label>
            <Field
              name="message"
              id="message"
              as="textarea"
              className="border rounded-md p-2 mb-2 resize-none"
              style={{ minHeight: "100px" }}
            ></Field>
            <ErrorMessage name="message" />

            <button
              type="submit"
              className="bg-[#06413D] p-3 rounded text-white"
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Contact;

import React, { useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import axios from "axios";
import LikedRecipeCard from "./LikedRecipeCard"; // Import the LikedRecipeCard component


const LikedRecipes = ({ likedRecipes, setLikedRecipes }) => {
  const { getUserEmail, isAuthenticated } = useAuth();
  // const [likedRecipes, setLikedRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const email = getUserEmail();
  const [isFetching, setIsFetching] = useState(false);
  const backendUrl =
    process.env.REACT_APP_BACKEND_SERVER || "http://localhost:3001";

  const fetchLikedRecipes = async (email) => {
    setIsFetching(true);
    try {
      if (!email) {
        throw new Error("User email is required");
      }

      const response = await axios.get(`${backendUrl}/api/liked_recipes`, {
        params: { email },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching liked recipes:", error);
      throw error;
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!email || !isAuthenticated()) return;
      setLoading(true);
      try {        
          const recipes = await fetchLikedRecipes(email);
          setLikedRecipes(recipes);
          setError(null);               
      } catch (Error) {
        setError("Failed to fetch liked recipes");
        setLikedRecipes([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData()
  }, [email]);

// const handleRemove = async (recipeUri) => {
//   try {
//     if (!email) {
//       throw new Error("User email is required");
//     }

//     // Perform the delete request
//     const response = await axios.delete(
//       "http://localhost:3001/api/liked_recipes",
//       {
//         data: { RECIPE_URI: recipeUri, USER_EMAIL: email },
//       }
//     );

//     // Check if the response was successful
//     if (response.status === 200 && response.data.success) {
//       // Update local state to remove the recipe if removal was successful
//       setLikedRecipes((prevRecipes) => {
//         const updatedRecipes = prevRecipes.filter(
//           (recipe) => recipe.RECIPE_URI !== recipeUri
//         );
//         console.log("Updated Recipes List:", updatedRecipes);
//         return updatedRecipes;
//       });
//     } else {
//       // Handle the case where the recipe was not found or was already removed
//       console.error(response.data.message || "Failed to remove recipe");
//       setError(response.data.message || "Failed to remove recipe");
//     }
//   } catch (error) {
//     // Handle network or unexpected errors
//     console.error("Error removing recipe:", error);
//     setError(error.response?.data?.message || "Failed to remove recipe");
//   }
// };


 

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!email || !isAuthenticated || isFetching) return; // Prevent fetch if already fetching or if email or auth status is not available

  //     setLoading(true);
  //     try {
  //       const recipes = await fetchLikedRecipes(email);
  //       setLikedRecipes(recipes);
  //       setError(null);
  //     } catch (err) {
  //       setError("Failed to fetch liked recipes");
  //       setLikedRecipes([]); // Clear previous recipes on error
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [email, isAuthenticated, isFetching]); 

  if (!isAuthenticated()) {
    return <p>You need to log in to see your liked recipes.</p>;
  }

  if (loading) return <p className="text-center text-gray-600">Loading...</p>;
  if (error) return <p className="text-center text-red-600">{error}</p>;

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Liked Recipes</h2>
      {likedRecipes.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {likedRecipes.map((recipe) => {
            return (
              <LikedRecipeCard
                key={recipe.id}
                recipe={recipe}
                // onRemove={handleRemove}
              />
            );
          })}
        </div>
      ) : (
        <p className="text-center text-gray-600">No liked recipes found.</p>
      )}
    </div>
  );
};

export default LikedRecipes;

import Card from "react-bootstrap/Card";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "./AuthContext";

const RecipeCard = ({ recipe, onLike }) => {
  const [liked, setLiked] = useState(false);
  const { getUserEmail } = useAuth();
  const email = getUserEmail();
  const backendUrl =
    process.env.REACT_APP_BACKEND_SERVER || "http://localhost:3001";

  useEffect(() => {
    // Function to check if the recipe is already liked
    const checkIfLiked = async () => {
      try {
        if (!email) return;

        const response = await axios.get(`${backendUrl}/api/liked_recipes`, {
          params: { email },
        });

        const likedRecipes = response.data || [];
        const isLiked = likedRecipes.some(
          (r) => r.recipe_uri === recipe?.recipe.uri
        );
        setLiked(isLiked);
      } catch (error) {
        console.error("Error fetching liked recipes:", error.message);
      }
    };

    checkIfLiked();
  }, [email, recipe?.recipe.uri]);

  const handleLike = async () => {
    try {
      if (!email) {
        alert("Please log in to like recipes.");
        return;
      }

      if (!recipe?.recipe) {
        alert("Recipe data is missing.");
        return;
      }

      if (liked) {
        // Remove from liked recipes
        const response = await axios.delete(`${backendUrl}/api/liked_recipes`, {
          data: {
            recipe_uri: recipe?.recipe.uri,
            user_email: email,
          },
        });
        if (response.status === 200) {
          setLiked(false);
          if (onLike) onLike(); // Notify parent component to refresh liked recipes
        } else {
          console.error("Unexpected response status:", response.status);
          alert("Failed to remove from liked recipes.");
        }
      } else {
        // Add to liked recipes
        const response = await axios.post(`${backendUrl}/api/liked_recipes`, {
          recipe_name: recipe?.recipe.label,
          recipe_uri: recipe?.recipe.uri,
          recipe_url: recipe?.recipe.url,
          recipe_image: recipe?.recipe.image,
          user_email: email,
        });
        if (response.status === 201) {
          setLiked(true);
          if (onLike) onLike(); // Notify parent component to refresh liked recipes
        } else {
          console.error("Unexpected response status:", response.status);
          alert("Failed to add to liked recipes.");
        }
      }      
    } catch (error) {
      console.error(
        "Error updating liked recipes:",
        error.response ? error.response.data : error.message
      );
      alert(
        "An error occurred while updating liked recipes. Please try again."
      );
    }
  };

  return (
    <Card
      className="w-[26rem] mx-1 text-center"
      style={{
        width: "20.2rem",
        height: "50rem",
      }}
    >
      <Card.Body>
        <Card.Text className="font-medium">
          <Card.Link
            className="font-bold text-2xl"
            href={recipe?.recipe.url}
            target="_blank"
          >
            {recipe?.recipe.label}
          </Card.Link>
        </Card.Text>
        <Card.Img variant="top" src={recipe?.recipe.image} />
        <Card.Subtitle>
          <h6 className="text-lg font-bold">Ingredients</h6>
          {recipe?.recipe.ingredientLines.map((ingredient, index) => (
            <ul key={index} style={{ listStyleType: "none" }}>
              <li>{ingredient}</li>
            </ul>
          ))}
          <Card.Link
            className="text-blue-500 hover:text-blue-700 font-bold"
            href={recipe.recipe.url}
            target="_blank"
          >
            Cooking Instructions
          </Card.Link>
        </Card.Subtitle>
        <Card.Footer>
          <button
            onClick={handleLike}
            className={`px-4 py-2 rounded-md font-semibold text-white transition-colors duration-300 ${
              liked
                ? "bg-green-500 hover:bg-green-600 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
          >
            {liked ? "Unlike" : "Like"}
          </button>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export default RecipeCard;

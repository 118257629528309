const Footer = () => {
  return (
    <footer className="bg-[#06413D] text-white fixed bottom-0 w-full mt-6">
      <div className=" flex justify-around items-center py-2">
        <h1>Copyright © Recipe Atlas</h1>
        <h1>Privacy Policy</h1>
        <h1>Terms of Service</h1>
        <img src="logo-no-background.svg" alt="logo" className="w-20" />
      </div>
    </footer>
  );
};
export default Footer;

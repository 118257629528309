import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Define the decodeJWT function
const decodeJWT = (token) => {
  try {
    // Split the JWT into its parts (header, payload, signature)
    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('JWT does not have 3 parts');
    }

    // Decode the payload (second part of the JWT)
    const payload = parts[1];
    const decoded = atob(payload); // Decode from Base64

    // Parse the JSON payload
    const parsed = JSON.parse(decoded);

    return parsed;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};


const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      localStorage.removeItem("authToken"); // Ensure state consistency
      setAuthToken(null);
    }
  }, [authToken]);

 

  const login = (token) => {
     console.log("Attempting to log in with token:", token);
    if (typeof token === "string" && token.trim() !== "") {
      try {
        // Check token format
        if (token.length < 10) {
          throw new Error("Token is too short");
        }

        // Store token and update state
        localStorage.setItem("authToken", token);
        setAuthToken(token);

      
        console.log("Token successfully stored and authToken state updated");

        // Navigate to protected route
        navigate("/dashboard");
      } catch (error) {
        console.error("Error during login:", error);
        // Optional: Set error state or show a notification
        // Example: setError("Login failed. Please try again.");
      }
    } else {
      console.error("Invalid token specified during login:", token);
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setAuthToken(null);    
    navigate("/login"); // Redirect to login or home page
  };

  const isAuthenticated = () => {
    return !!authToken;
  };

  const getUserEmail = () => {
    if (!authToken) return null;

    const decoded = decodeJWT(authToken);
    return decoded ? decoded.email : null;
  };

  return (
    <AuthContext.Provider value={{ authToken, login, logout, isAuthenticated, getUserEmail }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use auth context
const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col gap-2 text-center">
      <h2>404 Not Found</h2>
      <Link to="/">Go to Home</Link>
    </div>
  );
};
export default NotFoundPage;

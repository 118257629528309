// import { useState } from "react";
const SearchForm = ({ searchTerm, setSearchTerm, handleSearchSubmit, handleInputChange, searchValue }) => {
  // const [isFocused, setIsFocused] = useState(false);

  // const handleFocus = () => {
  //   setIsFocused(true);
  // };

  return (
    <div className="text-center mb-5 border-black">
      <form onSubmit={handleSearchSubmit}>
        <input
          type="text"
          // onFocus={handleFocus}
          // className={isFocused ? "focused" : ""}
          id="searchField"
          name="searchField"
          value={searchValue}
          placeholder="Search a recipe..."
          onChange={handleInputChange}
          className="rounded border-black border-2 text-2xl"
        />
        <button type="submit" className="rounded bg-gray-800 text-white text-2xl p-1rem">
          Submit
        </button>
      </form>
    </div>
  );
};
export default SearchForm;

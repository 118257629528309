// LikedRecipeCard.js
import React from "react";

const LikedRecipeCard = ({ recipe, onRemove }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <img
        style={{
          width: "450px",
          height: "400px",
        }}
        src={recipe.recipe_image || "https://via.placeholder.com/450x400"} // Fallback image URL if no image provided
        alt={recipe.recipe_name}
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <a
          href={recipe.recipe_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-lg font-semibold text-blue-600 hover:underline"
        >
          {recipe.recipe_name}
        </a>
        {/* <button
          className="bg-red-500 text-white text-xs px-2 py-1 rounded ml-4"
          onClick={() => onRemove(recipe.RECIPE_ID)}
        >
          Remove
        </button> */}
      </div>
    </div>
  );
};

export default LikedRecipeCard;

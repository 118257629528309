import LikedRecipes from "./LikedRecipes";
import RecipesList from "./RecipesList";
import SearchForm from "./SearchForm";
const Home = ({
  allRecipes,
  setAllRecipes,
  searchTerm,
  setSearchTerm,
  handleSearchSubmit,
  handleViewMore,
  hasMore,
  handleInputChange,
  searchValue,
  likedRecipes,
  setLikedRecipes,
  handleLike,
  userEmail,
  setUserEmail,
  
  
}) => {
  return (
    <main className=" mx-auto w-[90%] py-40">
      <div className="text-center font-bold  text-xl">
        <p className=" py-10">
          Indulge your senses and ignite your passion for cooking with Recipe
          Atlas, your ultimate destination for culinary inspiration.
          <br />
          Whether you're a seasoned chef or a culinary novice, our carefully
          curated collection of recipes caters to every skill level and taste
          preference.
        </p>
        <p>Use the search field below to start your recipe search. Use key words such as: beef, chicken, banana, rice, bread, pasta, etc...</p>
      </div>
      <div className="text-2lg m-t-2rem">
        <SearchForm
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleSearchSubmit={handleSearchSubmit}
          handleInputChange={handleInputChange}
          searchValue={searchValue}
          handleLike={handleLike}
        />

        <div>
          <RecipesList allRecipes={allRecipes} setAllRecipes={setAllRecipes} handleViewMore={handleViewMore} hasMore={hasMore} likedRecipes={likedRecipes} setLikedRecipes={setLikedRecipes} handleLike={handleLike} />
        </div>
        {/* <div>
          <LikedRecipes likedRecipes={likedRecipes} setLikedRecipes={setLikedRecipes} userEmail={userEmail} setUserEmail={setUserEmail} />
        </div> */}
      </div>
    </main>
  );
};
export default Home;

const About = () => {
  return (
    <div className=" mx-auto w-[80%] py-40">
      <br />
      <p>
        Welcome to Savory Delights, your go-to destination for culinary
        inspiration and gastronomic adventures. Whether you're a seasoned chef
        or a novice in the kitchen, our recipe website offers a treasure trove
        of delectable delights to tantalize your taste buds and elevate your
        cooking experience.
      </p>
      <br />
      <p>
        Dive into our extensive collection of recipes spanning diverse cuisines
        from around the globe. From comforting classics to innovative creations,
        we cater to every palate and dietary preference. Whether you're craving
        hearty comfort food, exploring plant-based options, or seeking quick and
        easy meals for busy weeknights, we've got you covered.
      </p>
    </div>
  );
};
export default About;

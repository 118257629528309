import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext"; // Adjust the import path if needed

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // For handling login errors
  const [isLoading, setIsLoading] = useState(false); // For loading state
  const navigate = useNavigate(); // Hook for navigation
  const { login } = useAuth(); // Get the login function from context
  const backendUrl = process.env.REACT_APP_BACKEND_SERVER || "http://localhost:3001";

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message
    setIsLoading(true); // Set loading state to true

    // Validate input fields
    if (!email || !password) {
      setError("Please enter both email and password.");
      setIsLoading(false); // Set loading state to false
      return;
    }

    try {
      // Log the request payload for debugging
      // console.log("Sending request with payload:", { email, password });

      // Make the API request
      const response = await axios.post(`${backendUrl}/api/login`, {
        email,
        password,
      });

      // Log the full response for debugging
      console.log("Response from server:", response);

      if (response.status === 200 && response.data.token) {
        login(response.data.token); // Save the token and set the user session
        navigate("/"); // Redirect to home or another page
      } else {
        // Handle case where no token is received
        setError("Login failed. No token received.");
      }
    } catch (error) {
      // Set a more detailed error message based on the error response
      if (error.response) {
        // Error response from the server
        console.error("Login failed:", error.response.data.message);
        setError(
          error.response.data.message ||
            "Login failed. Please check your credentials."
        );
      } else {
        // Network or other errors
        console.error("Login failed:", error.message);
        setError("Login failed. Please try again later.");
      }
    } finally {
      // Ensure loading state is false after completion
      setIsLoading(false); // Ensure loading state is false after completion
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div className="space-y-1">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-gray-700"
            >
              Email:
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div className="space-y-1">
            <label
              htmlFor="password"
              className="block text-sm font-semibold text-gray-700"
            >
              Password:
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          <button
            type="submit"
            className={`w-full px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading} // Disable button during loading
          >
            {isLoading ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;


import RecipeCard from "./RecipeCard";
// import axios from "axios";
// import { useState } from "react";

const RecipesList = ({ allRecipes, hasMore, handleViewMore, likedRecipes, setLikedRecipes, handleLike }) => {
   
 
  return (
    <>
      <div className="flex flex-wrap mx-auto w-[90%] ">
        {allRecipes?.hits?.map((recipe, index) => {
          return (
            <div key={index}>
              <RecipeCard recipe={recipe} handleLike={handleLike} />
            </div>
          );
        })}
      </div>
      {hasMore && <button onClick={handleViewMore}>View More</button>}
      {!hasMore && <p>No more results</p>}
    </>
  );
};
export default RecipesList;

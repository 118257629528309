import { useEffect, useState} from 'react';
import axios from 'axios';
// import sampleData from './data/sampleData.json';
import Home from "./components/Home";
// import SearchForm from './components/SearchForm';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import NotFoundPage from './components/NotFoundPage';
import { Routes, Route } from "react-router-dom";
import './App.css';
import { AuthProvider, useAuth } from './components/AuthContext';
import LikedRecipes from './components/LikedRecipes';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';

const apiKey = process.env.REACT_APP_API_KEY;
const apiId = process.env.REACT_APP_API_ID;



function App() {
  const [allRecipes, setAllRecipes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("beef");
  const [searchValue, setSearchValue] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [likedRecipes, setLikedRecipes] = useState([]);
  const { userEmail } = useAuth;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recipesResponse = await axios.get(
          `https://api.edamam.com/api/recipes/v2?type=public&q=${searchTerm}&app_id=${apiId}&app_key=${apiKey}` // correct api url
        );
        setAllRecipes(recipesResponse.data);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchData();
  }, [searchTerm]);

  // Fetch liked recipes for the logged-in user
  

 

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchTerm(searchValue);
    // setSearchTerm(searchTerm);
    // setSearchTerm("");
  };

  const handleViewMore = async () => {
    if (!hasMore) return; // No more results to fetch
    try {
      // Example URL from _links.next.href
      const nextUrl = allRecipes._links?.next?.href;
      console.log("nextUrl: ", nextUrl);

      const response = await axios.get(nextUrl);
      const nextPage = response.data.hits;
      console.log("Next page: ", nextPage);

      // Update state with new recipes
      setAllRecipes((prevAllRecipes) => ({
        ...prevAllRecipes,
        hits: [...prevAllRecipes.hits, ...nextPage],
      }));

      // Check if there are more pages to fetch
      if (response.data._links?.next) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more results:", error.message);
    }
  };

  // setAllRecipes(allRecipes);

  return (
    <AuthProvider>
      <header className="mx-auto ">
        <Navbar
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          setShowRegister={setShowRegister}
        />
      </header>
      <main className="w-[90%] mx-auto ">
        {/* {showLogin && <LoginPage onClose={() => setShowLogin(false)} />} */}

        <Routes>
          <Route
            path="/"
            element={
              <Home
                allRecipes={allRecipes}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearchSubmit={handleSearchSubmit}
                setAllRecipes={setAllRecipes}
                hasMore={hasMore}
                handleViewMore={handleViewMore}
                handleInputChange={handleInputChange}
                searchValue={searchValue}
                // likedRecipes={likedRecipes}
                // setLikedRecipes={setLikedRecipes}
                // handleLike={handleLike}
                // userEmail={userEmail}
              />
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                element={
                  <LikedRecipes
                    likedRecipes={likedRecipes}
                    setLikedRecipes={setLikedRecipes}
                  />
                }
              />
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </AuthProvider>
  );
}

export default App;
